import React, { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ForwardIcon, Paperclip } from "lucide-react";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import { MessageEditIcon, SavedMessageIcon } from "../../assets/icon";
import { fetchData, storage_url } from "../../utils/request";
import { NavLink, useNavigate } from "react-router-dom";

const MainContainer = styled.div`
  overflow-y: scroll;
  height: ${(props) => props.size - 100}px;
  padding-bottom: 3rem;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const PinnedHeader = styled.span`
  margin-left: 24px;
  margin-top: 20px;
  color: #171a1f;
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  display: block;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationCard = styled.div`
  width: 396px;
  height: 123px;
  //   margin: 16px 0 0 16px;
  background: ${(props) => (props.isSelected ? "#F3F4F6" : "#ffffff")};
  border: 0.1px solid ${(props) => (props.isSelected ? "#65d46a78" : "#ffffff")};
  //   border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
  /* opacity: 0;
  animation: ${fadeIn} 400ms ease-out forwards;
  animation-delay: ${(props) => props.index * 100 + 200}ms; */
  border-bottom: 1px solid silver;
  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const NotificationContent = styled.div`
  padding: 16px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => props.color || "#f3f4f6"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f494a;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

const UserName = styled.span`
  color: #323842;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
`;

const ReplyText = styled.span`
  color: #6e7787;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const TimeStamp = styled.span`
  color: #9095a0;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
`;

const MessagePreview = styled.div`
  color: #171a1f;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
`;

const EmailPreview = styled.div`
  color: #323842;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;

const StatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2acccf;
  margin-left: 8px;
`;

const FileIcon = styled(Paperclip)`
  margin-left: 8px;
  color: #9095a0;
`;

const ChatWrapper = styled.div`
  color: #171a1f;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 11px;
  width: 396px;
`;

const UserDialogue = ({
  setSelectChat,
  selectChat,
  userNameChat,
  managerId = null,
}) => {
  const { user } = SelectAuth();
  const { height } = useWindowSize();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const loadMoreData = async () => {
    try {
      const newData = await fetchData(page, managerId); // Use fetchData from request.js
      setTableData([...tableData, ...newData.data]);
    } catch (error) {
      console.error("Failed to load data:", error);
    }
  };

  useEffect(() => {
    loadMoreData();
  }, [page, managerId]);

  return (
    <>
      <ChatWrapper>
        <div style={{ marginLeft: "21px", fontSize: 26 }}>
          {["manager"].includes(user?.role) ? "Мои чаты" : "Чаты"}
        </div>
        {["owner"].includes(user?.role) && (
          <div
            style={{ marginRight: "10px", cursor: "pointer", scale: "-1 1" }}
            onClick={() => {
              setSelectChat(false);
              navigate("/messages")
            }}
          >
            <ForwardIcon />
          </div>
        )}
      </ChatWrapper>
      <MainContainer size={height}>
        {tableData
          ?.sort(
            (a, b) =>
              (b?.chat?.peer === 777777 ? 1 : 0) -
              (a?.chat?.peer === 777777 ? 1 : 0)
          )
          ?.map((notification, index) => (
            <NavLink
              style={{ textDecoration: "none" }}
              key={notification?.id}
              to={
                "/messages/" +
                notification?.id +
                "?account_id=" +
                notification?.chat?.account_id
              }
            >
              <NotificationCard index={index}>
                <NotificationContent>
                  <UserInfo>
                    <Avatar>
                      {notification?.chat?.peer === 777777 ? (
                        <SavedMessageIcon />
                      ) : (
                        <img
                          width={40}
                          height={40}
                          style={{ borderRadius: 50 }}
                          src={
                            (notification?.chat?.info?.photo?.path &&
                              storage_url +
                                notification?.chat?.info?.photo?.path +
                                "/" +
                                notification?.chat?.info?.photo?.name) ||
                            "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
                          }
                          alt=""
                        />
                      )}
                    </Avatar>

                    <UserName>
                      {notification?.chat?.peer === 777777 ? (
                        "Saved Messages"
                      ) : (
                        <>
                          {notification?.chat?.info?.first_name ||
                            "Пользователь"}{" "}
                          {notification?.chat?.info?.last_name || ""}{" "}
                          <ReplyText>ответил вам</ReplyText>
                        </>
                      )}
                    </UserName>
                    <TimeStamp>
                      {new Date(notification?.created_at).toLocaleDateString()}
                    </TimeStamp>
                    <StatusCircle />
                    {/* {notification?.hasAttachment && <FileIcon size={16} />} */}
                  </UserInfo>
                  <MessagePreview>{notification?.message}</MessagePreview>
                  <EmailPreview>
                    {notification?.chat?.info?.phone?.phone_number}
                  </EmailPreview>
                </NotificationContent>
              </NotificationCard>
            </NavLink>
          ))}
      </MainContainer>
    </>
  );
};

export default UserDialogue;
