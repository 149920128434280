import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Dashboard from "./components/Dashboard";
import UserProfile from "./components/UserProfile";
import ChatWindow from "./components/ChatWindow";
import KanbanBoard from "./components/KanbanBoard";
import Dialogue from "./components/Dialogue";
import Analytics from "./components/Analytics";
import Users from "./components/Users";
import Settings from "./components/Settings";
import Links from "./components/Links";
import Downloads from "./components/Downloads";
import Consumables from "./components/Consumables";
import Warehouse from "./components/Warehouse";
import Comand from "./components/Comand";

import MainLayout from "./route/MainLayout";
import LoginLayout from "./route/LoginLayout";
import LoginPage from "./page/Login";
import { Toaster } from "react-hot-toast";

const AppWrapper = styled.div`
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(18, 15, 40, 0.12);
`;

const ContentWrapper = styled.div`
  display: flex;
`;

export default function App() {
  return (
    <AppWrapper>
      <Routes>
        <Route
          path="/login"
          element={
            <LoginLayout>
              <LoginPage />
            </LoginLayout>
          }
        />
        <Route
          path="*"
          element={
            <MainLayout>
              <ContentWrapper>
                <Routes>
                  {/* <Route path="/" element={<Dashboard />} />
                  <Route
                    path="/dashboard/:id"
                    element={
                      <>
                        <UserProfile />
                        <ChatWindow />
                      </>
                    }
                  /> */}
                  <Route path="/" element={<Analytics />} />
                  <Route path="/documents" element={<KanbanBoard />} />
                  <Route path="/messages" element={<Dialogue />} />
                  <Route path="/messages/:id" element={<Dialogue />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/links" element={<Links />} />
                  <Route path="/downloads" element={<Downloads />} />
                  <Route path="/command" element={<Comand />} />
                  <Route
                    path="/consumables/warehouse"
                    element={<Warehouse />}
                  />
                  <Route
                    path="/category/consumables"
                    element={<Consumables />}
                  />
                </Routes>
              </ContentWrapper>
            </MainLayout>
          }
        />
      </Routes>
      <Toaster />
    </AppWrapper>
  );
}
