import React, { useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
// import request from "../utils/request";
// import { useLocation, useNavigate } from "react-router-dom";
const MainLayout = ({ children }) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  // useEffect(() => {
  //   const fetchOffers = async () => {
  //     try {
  //       const response = await request.get("/userInfo"); // Adjust endpoint
  //       localStorage.setItem("id", response.data.id);
  //       if (response.data.telegram_status == 0) {
  //         navigate("/login?id=1");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching offers:", error);
  //     } finally {
  //     }
  //   };

  //   fetchOffers();
  // }, [location]);
  return (
    <div>
      <Header />
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ flex: 1 }}>{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
