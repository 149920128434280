// src/components/Warehouse/Warehouse.jsx
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  ChevronDown,
  ChevronUp,
  Edit,
  Trash,
  Download,
} from "lucide-react";
import Modal from "./Modal";
import ModalEdit from "./EditModal";
import SelectFilter from "./SelectFilter"; // Assuming this is a component for filtering
import useWindowWidth from "../../hooks/useWindowWidth";
import { SelectAuth } from "../../redux/selectors";
import request, { file_url } from "../../utils/request"; // Assume you have a utility for making API requests
import toast from "react-hot-toast";
import { IconPlus } from "../../assets/icon"; // Assuming IconPlus is a valid import
import { useNavigate } from "react-router-dom";
// Styled Components
const DashboardContainer = styled.div`
  font-family: "Lato", sans-serif;
  width: 100%;
  max-width: ${(props) => props.size}px;
  margin: 0 auto;
  background: #ffffff;
  padding: 2rem;
  overflow-y: auto;
  height: 100vh;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    align-items: start;
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  width: 146px;
  text-align: center;
  transition: background 0.2s;

  &:hover {
    background: #b8bcf6;
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 8px 12px;
    font-size: 14px;
  }
`;

const FiltersContainer = styled.form`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SelectButton = styled.select`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-width: 150px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #f5f5f5;
  }

  @media (max-width: 768px) {
    padding: 5px;
    height: auto;
    min-width: auto;
    width: 100%;
  }
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #5a51d1;
  }

  &:disabled {
    background: #b3b3b3;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 8px 16px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #565e6c transparent;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #565e6c;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #434a5d;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 960px;

  @media (max-width: 768px) {
    min-width: 0;
  }
`;

const TableHeader = styled.th`
  background: #f8f9fa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: #565e6c;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding: 12px;
`;

const TableCell = styled.td`
  border-bottom: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 10px 8px;
  }
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

const DownloadButton = styled(ActionButton)`
  background: #3498db;
  color: white;
  border-radius: 4px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background: #2980b9;
  }
`;

const BtnWarehouse = styled.button`
  background-color: #379ae6;
  color: white;
  padding: 7px 12px;
  font-size: 9px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background-color: #379ae6c4;
  }
`;

// Styled Components for Accordion
const AccordionItem = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const AccordionHeader = styled.div`
  background: #fafafb;
  padding: 15px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const AccordionTitle = styled.div`
  font-weight: 600;
  color: #565e6c;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AccordionContent = styled.div`
  padding: 15px 12px;
  background: #ffffff;
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ChevronIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingCell = styled.div`
  height: 20px;
  background: #f0f1f2;
  border-radius: 4px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  background-color: #f8d7da;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

const Warehouse = () => {
  const { user } = SelectAuth();
  const width = useWindowWidth(); // Get current window width
  const isMobile = width < 768; // Define mobile breakpoint
  const navigate = useNavigate();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [warehouseData, setWarehouseData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditId, setIsEditId] = useState(null);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // For error handling

  const [activeAccordion, setActiveAccordion] = useState(null); // For accordion state

  // Fetch categories from the API when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await request.get("/category-expenses"); // Replace with your actual API endpoint
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Не удалось загрузить категории. Пожалуйста, попробуйте позже.");
      }
    };

    fetchCategories();
  }, []);

  // Fetch warehouse data when the component loads or modals change
  useEffect(() => {
    const fetchWarehouseData = async () => {
      try {
        setLoading(true);
        const response = await request.get("/base-expenses"); // Assume /base-expenses endpoint
        setTableData(response.data); // Assuming response.data contains warehouse items
        setError(null); // Reset error state
      } catch (error) {
        console.error("Error fetching warehouse data:", error);
        setError("Не удалось загрузить данные склада. Пожалуйста, попробуйте позже.");
      } finally {
        setLoading(false);
      }
    };
    fetchWarehouseData();
  }, [isModalOpen, isModalEditOpen]);

  // Handle form submission to filter warehouse data
  const formSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    setLoading(true);
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    try {
      const response = await request.get("/base-expense/filter", {
        params: data,
      }); // Assume /base-expense/filter endpoint
      setTableData(response.data); // Assuming response.data contains warehouse items
      setError(null); // Reset error state
    } catch (error) {
      console.error("Error fetching filtered warehouse data:", error);
      setError("Не удалось применить фильтр. Пожалуйста, попробуйте позже.");
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission to add or update a category
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (isEditModal && isEditId !== null) {
        // Update the existing category
        await request.put(`/base-expenses/${isEditId}`, data); // API call to update
        const updatedItems = tableData.map((item) =>
          item.id === isEditId ? { ...item, ...data } : item
        );
        setTableData(updatedItems);
        setIsEditId(null);
      } else {
        // Add a new category
        const response = await request.post("/base-expenses", data); // API call to create
        setTableData([...tableData, response.data]);
      }
      setError(null); // Reset error state
    } catch (error) {
      console.error("Error submitting warehouse data:", error);
      setError("Не удалось сохранить данные. Пожалуйста, попробуйте позже.");
    } finally {
      setLoading(false);
      setIsModalOpen(false);
      setIsModalEditOpen(false);
    }
  };

  // Handle deleting a category
  const handleDelete = async (id) => {
    try {
      await request.delete(`/base-expenses/${id}`); // DELETE API request to remove item
      setTableData(tableData.filter((item) => item.id !== id));
      toast.success("Категория успешно удалена.");
    } catch (error) {
      console.error("Error deleting warehouse item:", error);
      toast.error("Вы не можете удалить эту категорию.");
    }
  };

  // Handle editing a category
  const handleEditWarehouse = (item) => {
    setWarehouseData(item);
    setIsEditId(item.id);
    setIsEditModal(true);
    setIsModalOpen(true);
  };

  // Handle adding a new warehouse item
  const handleAddWarehouse = async (newData) => {
    try {
      const response = await request.post("/base-expenses", newData); // POST request to add new item
      setTableData([...tableData, response.data]); // Add the new item to the list
      toast.success("Категория успешно добавлена.");
    } catch (error) {
      console.error("Error adding warehouse item:", error);
      toast.error("Не удалось добавить категорию.");
    }
  };

  // Handle updating a warehouse item
  const handleUpdateWarehouse = async (id, updatedData) => {
    try {
      const response = await request.put(`/base-expenses/${id}`, updatedData); // PUT request to update item
      const updatedItems = tableData.map((item) =>
        item.id === id ? response.data : item
      );
      setTableData(updatedItems); // Update the list with the edited item
      toast.success("Категория успешно обновлена.");
    } catch (error) {
      console.error("Error updating warehouse item:", error);
      toast.error("Не удалось обновить категорию.");
    }
  };

  return (
    <DashboardContainer size={width}>
      {/* Error Message */}
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {/* Modal for Adding and Editing */}
      <Modal
        onClose={() => {
          setIsModalOpen(false);
          setIsEditId(null);
        }}
        isOpen={isModalOpen}
        onSubmit={onSubmit}
        isEditModal={isEditModal}
        editId={isEditId}
        warehouseData={warehouseData}
      />

      {/* Edit Modal */}
      <ModalEdit
        onClose={() => {
          setIsModalEditOpen(false);
          setIsEditId(null);
        }}
        isOpen={isModalEditOpen}
        onSubmit={onSubmit}
        warehouseData={warehouseData}
      />

      {/* Top Row with Add Button and Filters */}
      <TopRow>
        {["owner"].includes(user?.role) ? (
          <AddButton
            onClick={() => {
              setIsModalOpen(true);
              setIsEditModal(false); // Set to false for new entry
            }}
          >
            Расходник <IconPlus />
          </AddButton>
        ) : (
          <div style={{ padding: "10px 20px" }}></div>
        )}
          <FiltersContainer onSubmit={formSubmit} >
            <SelectButton name="category_id">
              <option value="">Категория</option>
              {offers.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </SelectButton>
            <SelectButton name="status">
              <option value="">Статус</option>
              <option value="TO_DO">TO DO</option>
              <option value="DONE">DONE</option>
              <option value="UNDONE">UNDONE</option>
              <option value="PROGRESS">PROGRESS</option>
            </SelectButton>
            <SearchButton type="submit" disabled={loading}>
              {loading ? "Поиск..." : "Поиск"}
            </SearchButton>
          </FiltersContainer>
      </TopRow>

      {/* Table or Accordion */}
      <TableContainer>
        {isMobile ? (
          // Render Accordion for Mobile
          loading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <AccordionItem key={index}>
                <AccordionHeader>
                  <LoadingCell style={{ width: "30px" }} />
                  <ChevronIcon>
                    <LoadingCell style={{ width: "20px", height: "20px" }} />
                  </ChevronIcon>
                </AccordionHeader>
                <AccordionContent>
                  <LoadingCell style={{ width: "100%" }} />
                  <LoadingCell style={{ width: "100%" }} />
                  <LoadingCell style={{ width: "100%" }} />
                </AccordionContent>
              </AccordionItem>
            ))
          ) : tableData.length === 0 ? (
            <p>Нет данных для отображения.</p>
          ) : (
            tableData.map((item) => (
              <AccordionItem key={item.id}>
                <AccordionHeader
                  onClick={() =>
                    setActiveAccordion(
                      activeAccordion === item.id ? null : item.id
                    )
                  }
                  role="button"
                  aria-expanded={activeAccordion === item.id}
                  aria-controls={`accordion-content-${item.id}`}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setActiveAccordion(
                        activeAccordion === item.id ? null : item.id
                      );
                    }
                  }}
                >
                  <AccordionTitle>
                    #{item.id} - {item.title}
                  </AccordionTitle>
                  <ChevronIcon>
                    {activeAccordion === item.id ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </ChevronIcon>
                </AccordionHeader>
                {activeAccordion === item.id && (
                  <AccordionContent id={`accordion-content-${item.id}`}>
                    <div>
                      <strong>Категория:</strong> {item.category_expense_id}
                    </div>
                    <div>
                      <strong>Поставщик:</strong> {item.supplier}
                    </div>
                    <div>
                      <strong>Цена ед:</strong> {item.price}
                    </div>
                    <div>
                      <strong>Стоимость:</strong> {item.quantity2}
                    </div>
                    <div>
                      <strong>Всего:</strong> {item.quantity}
                    </div>
                    <div>
                      <strong>Распределено:</strong> {item.distribute}
                    </div>
                    <div>
                      <a
                        href={file_url + item?.file}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <DownloadButton>
                          <Download size={16} /> Скачать
                        </DownloadButton>
                      </a>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <ActionButton
                        onClick={() => handleEditWarehouse(item)}
                        aria-label={`Редактировать ${item.title}`}
                      >
                        <Edit size={16} color="#379AE6" />
                      </ActionButton>
                      <ActionButton
                        onClick={() =>
                          window.confirm(
                            "Вы уверены, что хотите удалить!"
                          ) && handleDelete(item.id)
                        }
                        aria-label={`Удалить ${item.title}`}
                      >
                        <Trash size={16} color="#e74c3c" />
                      </ActionButton>
                      <BtnWarehouse
                        onClick={() => {
                          navigate(`/distribute?id=${item.id}`);
                        }}
                      >
                        Распределить
                      </BtnWarehouse>
                    </div>
                  </AccordionContent>
                )}
              </AccordionItem>
            ))
          )
        ) : (
          // Render Table for Desktop
          <Table>
            <thead>
              <tr>
                <TableHeader>Название</TableHeader>
                <TableHeader>Категория</TableHeader>
                <TableHeader>Поставщик</TableHeader>
                <TableHeader>Цена ед</TableHeader>
                <TableHeader>Стоимость</TableHeader>
                <TableHeader>Всего</TableHeader>
                <TableHeader>Распределено</TableHeader>
                <TableHeader>Файл</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 3 }).map((_, index) => (
                  <Tr key={index}>
                    <TableCell>
                      <LoadingCell style={{ width: "100%" }} />
                    </TableCell>
                    <TableCell>
                      <LoadingCell style={{ width: "100%" }} />
                    </TableCell>
                    <TableCell>
                      <LoadingCell style={{ width: "100%" }} />
                    </TableCell>
                    <TableCell>
                      <LoadingCell style={{ width: "100%" }} />
                    </TableCell>
                    <TableCell>
                      <LoadingCell style={{ width: "100%" }} />
                    </TableCell>
                    <TableCell>
                      <LoadingCell style={{ width: "100%" }} />
                    </TableCell>
                    <TableCell>
                      <LoadingCell style={{ width: "100%" }} />
                    </TableCell>
                    <TableCell>
                      <LoadingCell style={{ width: "100%" }} />
                    </TableCell>
                    <TableCell>
                      <LoadingCell style={{ width: "60px" }} />
                    </TableCell>
                  </Tr>
                ))
              ) : tableData.length === 0 ? (
                <tr>
                  <TableCell colSpan="9">Нет данных для отображения.</TableCell>
                </tr>
              ) : (
                tableData.map((item) => (
                  <Tr key={item.id}>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.category_expense_id}</TableCell>
                    <TableCell>{item.supplier}</TableCell>
                    <TableCell>{item.price}</TableCell>
                    <TableCell>{item.quantity2}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.distribute}</TableCell>
                    <TableCell>
                      <a
                        href={file_url + item?.file}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <DownloadButton>
                          <Download size={16} /> Скачать
                        </DownloadButton>
                      </a>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ActionButton
                          onClick={() => handleEditWarehouse(item)}
                          aria-label={`Редактировать ${item.title}`}
                        >
                          <Edit size={16} color="#379AE6" />
                        </ActionButton>
                        <ActionButton
                          onClick={() =>
                            window.confirm(
                              "Вы уверены, что хотите удалить!"
                            ) && handleDelete(item.id)
                          }
                          aria-label={`Удалить ${item.title}`}
                        >
                          <Trash size={16} color="#e74c3c" />
                        </ActionButton>
                        <BtnWarehouse
                          onClick={() => {
                            navigate(`/distribute?id=${item.id}`);
                          }}
                        >
                          Распределить
                        </BtnWarehouse>
                      </div>
                    </TableCell>
                  </Tr>
                ))
              )}
            </tbody>
          </Table>
        )}
      </TableContainer>
    </DashboardContainer>
  );
};

export default Warehouse;
