// src/components/Consumables/Consumables.jsx
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Plus, SquarePen, ChevronDown, ChevronUp } from "lucide-react";
import Modal from "./Modal";
import request from "../../utils/request"; 
import useWindowWidth from "../../hooks/useWindowWidth"; // Import the custom hook
import { useNavigate } from "react-router-dom";

// Styled Components
const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  font-family: "Lato", sans-serif;
  padding: 3rem;
  overflow-y: auto;
  height: 100vh;

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  @media (max-width: 768px) {
    padding: 1.5rem;
    height: auto;
  }
`;

const ContainerWrapperLink = styled.div`
  overflow-y: scroll;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const Table = styled.table`
  width: 100%;
  max-width: 960px;
  border-collapse: collapse;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    width: 100%;
    display: block;
    overflow-x: auto;
  }
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;

  @media (max-width: 768px) {
    padding: 10px 8px;
  }
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
  background: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};
  transition: background 0.2s;

  &:hover {
    background: ${(props) => (props.edit ? "#2b7cd1" : "#a12727")};
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  margin-bottom: 15px;
  transition: background 0.2s;

  &:hover {
    background: #b8bcf6;
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 8px 12px;
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LoadingCell = styled.div`
  height: 20px;
  background: #f0f1f2;
  border-radius: 4px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

// Additional Styled Components for Accordion
const AccordionItem = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const AccordionHeader = styled.div`
  background: #fafafb;
  padding: 15px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const AccordionTitle = styled.div`
  font-weight: 600;
  color: #565e6c;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AccordionContent = styled.div`
  padding: 15px 12px;
  background: #ffffff;
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ChevronIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Consumables Component
const Consumables = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(0);
  const [offers, setOffers] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const width = useWindowWidth(); // Get current window width
  const isMobile = width < 768; // Define mobile breakpoint
  const navigate = useNavigate();

  // Fetch categories from the API when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const response = await request.get("/category-expenses"); // Replace with your actual API endpoint
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        // Optionally, handle error state here
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [update]);

  // Handle form submission to add or update a category
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editIndex !== null) {
        // Update the existing category
        await request.put(`/category-expenses/${offers[editIndex].id}`, data); // API call to update
        const newOffers = [...offers];
        newOffers[editIndex] = { ...newOffers[editIndex], ...data };
        setOffers(newOffers);
        setEditIndex(null);
      } else {
        // Add a new category
        const response = await request.post("/category-expenses", data); // API call to create
        setOffers([...offers, response.data]);
      }
      setUpdate(update + 1);
    } catch (error) {
      console.error("Error submitting category:", error);
      // Optionally, handle error state here
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  // Handle deleting a category
  const deleteOffer = async (index) => {
    try {
      await request.delete(`/category-expenses/${offers[index].id}`); // API call to delete
      const newOffers = offers.filter((_, i) => i !== index);
      setOffers(newOffers);
      setUpdate(update + 1);
    } catch (error) {
      console.error("Error deleting category:", error);
      // Optionally, handle error state here
    }
  };

  // Handle editing a category
  const editOffer = (index) => {
    setEditIndex(index);
    setIsModalOpen(true);
  };

  // Toggle accordion
  const toggleAccordion = (teamId) => {
    setEditIndex(null); // Reset edit index when toggling accordion
    setIsModalOpen(false); // Ensure modal is closed when toggling accordion
    setActiveAccordion((prev) => (prev === teamId ? null : teamId));
  };

  const [activeAccordion, setActiveAccordion] = useState(null); // For accordion state

  return (
    <MainContainer>
      <Modal
        onClose={() => {
          setIsModalOpen(false);
          setEditIndex(null);
        }}
        isOpen={isModalOpen}
        onSubmit={onSubmit}
        defaultValues={editIndex !== null ? offers[editIndex] : {}}
      />
      <ContainerWrapperLink>
        <AddButton onClick={() => setIsModalOpen(true)}>
          Категория <Plus size={16} />
        </AddButton>
        {isMobile ? (
          // Render Accordion for Mobile
          isLoading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <AccordionItem key={index}>
                <AccordionHeader>
                  <LoadingCell style={{ width: "30px" }} />
                  <ChevronIcon>
                    <LoadingCell style={{ width: "20px", height: "20px" }} />
                  </ChevronIcon>
                </AccordionHeader>
                <AccordionContent>
                  <LoadingCell style={{ width: "100%" }} />
                  <LoadingCell style={{ width: "100%" }} />
                  <LoadingCell style={{ width: "100%" }} />
                </AccordionContent>
              </AccordionItem>
            ))
          ) : (
            offers.map((offer, index) => (
              <AccordionItem key={offer.id}>
                <AccordionHeader onClick={() => toggleAccordion(offer.id)}>
                  <AccordionTitle>
                    #{offer.id} - {offer.name}
                  </AccordionTitle>
                  <ChevronIcon>
                    {activeAccordion === offer.id ? (
                      <ChevronUp size={20} />
                    ) : (
                      <ChevronDown size={20} />
                    )}
                  </ChevronIcon>
                </AccordionHeader>
                {activeAccordion === offer.id && (
                  <AccordionContent>
                    <div>
                      <strong>Статус:</strong> {offer.status || "Активен"}
                    </div>
                    <div>
                      <ActionButton edit onClick={() => editOffer(index)}>
                        Редактировать
                      </ActionButton>
                      <ActionButton
                        onClick={() =>
                          window.confirm(
                            "Вы уверены, что хотите удалить эту категорию?"
                          ) && deleteOffer(index)
                        }
                      >
                        Удалить
                      </ActionButton>
                    </div>
                  </AccordionContent>
                )}
              </AccordionItem>
            ))
          )
        ) : (
          // Render Table for Desktop
          <Table>
            <thead>
              <tr>
                <Th>Номер</Th>
                <Th>Категория</Th>
                <Th>Статус</Th>
                <Th>Дополнительно</Th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <Td colSpan="4">
                    <LoadingCell style={{ width: "100%" }} />
                  </Td>
                </tr>
              ) : (
                offers.map((offer, index) => (
                  <Tr key={offer.id}>
                    <Td>#{offer.id}</Td>
                    <Td>
                      {offer.name}
                      <SquarePen
                        color="#379AE6"
                        cursor="pointer"
                        size={22}
                        onClick={() => editOffer(index)}
                        style={{ float: "right" }}
                        aria-label={`Редактировать категорию ${offer.name}`}
                      />
                    </Td>
                    <Td>{offer.status || "Активен"}</Td>
                    <Td>
                      <ActionButton edit onClick={() => editOffer(index)}>
                        Редактировать
                      </ActionButton>
                      <ActionButton
                        onClick={() =>
                          window.confirm(
                            "Вы уверены, что хотите удалить эту категорию?"
                          ) && deleteOffer(index)
                        }
                      >
                        Удалить
                      </ActionButton>
                    </Td>
                  </Tr>
                ))
              )}
            </tbody>
          </Table>
        )}
      </ContainerWrapperLink>
    </MainContainer>
  );
};

export default Consumables;
