import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ChevronDown,
  Plus,
  AlertCircle,
  Edit,
  User,
  Package,
  Globe,
  Layers,
  Archive,
} from "lucide-react";
import Modal from "./Modal";
import { platforms, useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request from "../../utils/request";

// Styled Components

const DashboardContainer = styled.div`
  font-family: "Lato", sans-serif;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  padding: 3rem;
  overflow-y: auto;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Select = styled.div`
  position: relative;
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-width: 150px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const SelectIcon = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const SelectOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
`;

const SelectOption = styled.div`
  padding: 8px 10px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const AddButton = styled.button`
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 16px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  width: 146px;
  text-align: center;

  &:hover {
    background: #b8bcf6;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
`;

const ScrollableTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  max-width: ${(props) => props.size - 100}px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const TableHeader = styled.th`
  background: #f8f9fa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: #565e6c;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding: 12px;
  white-space: nowrap;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TableCell = styled.td`
  border-bottom: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 14px;

  @media (max-width: 768px) {
    display: block;
    padding: 8px 12px;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  }
`;

const StatusSelect = styled(SelectButton)`
  width: 120px;
  background: #f3f4f6;
  border: none;
`;

const SelectStatus = styled.div`
  position: relative;
  width: 120px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Tr = styled.tr`
  white-space: nowrap;
  &:hover {
    background-color: #fff4f0;
  }

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
  }
`;

const StatusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 38px;
  background: #f3f4f6;
  border: none;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background: #e5e7eb;
  }
`;

const StatusOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 10;
`;

const StatusOption = styled.div`
  padding: 8px 10px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

// Accordion Styled Components

const AccordionContainer = styled.div`
  width: 100%;
`;

const AccordionItem = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
`;

const AccordionHeader = styled.div`
  background: #f8f9fa;
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AccordionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const AccordionIcon = styled.div`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const AccordionContent = styled.div`
  padding: 12px 16px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  background: #ffffff;
`;

const AccordionDetail = styled.div`
  margin-bottom: 8px;

  & > strong {
    display: inline-block;
    width: 120px;
    color: #555;
  }
`;

// Main Dashboard Component

const Dashboard = () => {
  const { user } = SelectAuth();
  const { width } = useWindowSize();
  const [filters, setFilters] = useState({
    general: { name: "", value: "" },
    user: { name: "Пользователь", value: "" },
    offer: { name: "Оффер", value: "" },
    platform: { name: "Площадка", value: "" },
    status: { name: "Статус", value: "" },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [updateTask, setUpdateTask] = useState(null);
  const [openStatusIndex, setOpenStatusIndex] = useState(null);
  const [createTitleMd, setCreateTitleMd] = useState("Добавить залив");
  const [offers, setOffers] = useState([]);
  const [distribute, setDistribute] = useState([]);
  const [offerId, setOfferId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [error, setError] = useState(null); // For error handling

  // Fetch existing data if in edit mode

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await request.get("/users"); // Fetch users from the backend API
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Не удалось загрузить пользователей.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch users from API
  useEffect(() => {
    fetchUsers();
  }, []);

  async function search() {
    setLoading(true);
    const outDate = {
      user_id: filters?.user?.value,
      offer_id: filters?.offer?.value,
      link: filters?.platform?.value,
    };
    try {
      const response = await request.get("/flood", { params: outDate }); // Adjust endpoint
      setTasks(response?.data?.data);
      setStatuses(response?.data?.map((task) => task?.status));
    } catch (error) {
      console.error("Error fetching offers:", error);
      setError("Не удалось выполнить поиск.");
    } finally {
      setLoading(false);
    }
  }

  const handleSelectClick = (filterName) => {
    setOpenSelect(openSelect === filterName ? null : filterName);
  };

  const handleOptionSelect = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
    setOpenSelect(null);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await request.get("/category-expenses"); // Replace with your actual API endpoint
        setOffers(response?.data);
        const dist = await request.get("/base-expenses"); // Replace with your actual API endpoint
        setDistribute(dist?.data);
        const offer = await request.get("/offers"); // Replace with your actual API endpoint
        setOfferId(offer?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Не удалось загрузить категории.");
      }
    };

    fetchCategories();
  }, []);

  // Fetch tasks data on mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await request.get("/flood", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTasks(response?.data?.data);
        setStatuses(response?.data?.map((task) => task?.status));
      } catch (error) {
        console.error("Error fetching tasks:", error);
        setError("Не удалось загрузить задачи.");
      } finally {
        setLoading(false);
      }
    };
    if (!isModalOpen) fetchData();
  }, [isModalOpen]);

  // Handle status click and toggle dropdown
  const handleStatusClick = (index) => {
    setOpenStatusIndex(openStatusIndex === index ? null : index);
  };

  // Handle status selection and update task status via API
  const handleStatusSelect = async (index, value) => {
    const updatedStatuses = [...statuses];
    updatedStatuses[index] = value;

    // Optimistically update the UI
    setStatuses(updatedStatuses);
    setOpenStatusIndex(null);

    // Make an API call to update the status
    try {
      const token = localStorage.getItem("token");
      const taskId = tasks[index].id; // Assuming tasks[index] has an `id`
      await request.put(
        `/tasks/${taskId}`,
        { status: value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error updating task status:", error);
      setError("Не удалось обновить статус задачи.");
      // Revert the status update in case of error
      const revertedStatuses = [...statuses];
      revertedStatuses[index] = tasks[index].status;
      setStatuses(revertedStatuses);
    }
  };

  // Modal handling for adding and editing tasks
  const CreateDown = (title, taskId = null) => {
    setUpdateTask(taskId);
    setCreateTitleMd(title);
    setIsModalOpen(true);
  };

  const renderSelect = (filterName, icon, dataSelect = []) => (
    <Select key={filterName}>
      <SelectButton onClick={() => handleSelectClick(filterName)}>
        <SelectIcon>{icon}</SelectIcon>
        {filters[filterName]?.name} <ChevronDown size={16} />
      </SelectButton>
      {openSelect === filterName && (
        <SelectOptions>
          {dataSelect?.map((item, idx) => (
            <SelectOption
              key={idx}
              onClick={() => handleOptionSelect(filterName, item)}
            >
              {item.name}
            </SelectOption>
          ))}
        </SelectOptions>
      )}
    </Select>
  );

  const toggleAccordion = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  return (
    <DashboardContainer>
      <Modal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={createTitleMd}
        taskId={updateTask}
        onSuccess={() => {
          setIsModalOpen(false);
          search(); // Refresh the task list after successful update
        }}
      />
      <TopRow>
        <AddButton onClick={() => CreateDown("Добавить залив", null)}>
          <Plus size={16} />
          Залив
        </AddButton>
        <FiltersContainer>
          {["owner"].includes(user?.role) && (
            <>
              {/* {renderSelect("general", <Layers size={16} />, [
                "Общий",
                "По баерам",
                "По тимлидам",
              ])} */}
              {renderSelect("user", <User size={16} />, [
                ...users?.map((item) => ({
                  name: item?.name,
                  value: item?.id,
                })),
              ])}
            </>
          )}
          {/* {["team_lead"].includes(user?.role) &&
            renderSelect("personal", <User size={16} />, [
              "Личный",
              "Моя команда",
            ])} */}
          {renderSelect("offer", <Package size={16} />, [
            ...offerId?.map((item) => ({
              name: item?.offer_name,
              value: item?.id,
            })),
          ])}
          {renderSelect("platform", <Globe size={16} />, [
            ...platforms?.map((item) => ({
              name: item,
              value: item,
            })),
          ])}
          {/* {renderSelect("status", <Layers size={16} />, [
            "Активный",
            "Не активный",
            "Архивировать",
          ])} */}
          {/* {renderSelect("archive", <Archive size={16} />)} */}
          <AddButton
            onClick={search}
            style={{
              opacity: loading ? "0.4" : "1",
              cursor: loading ? "not-allowed" : "pointer",
            }}
            disabled={loading}
          >
            {loading ? "Поиск..." : "Поиск"}
          </AddButton>
        </FiltersContainer>
      </TopRow>
      {width > 768 ? (
        <TableContainer>
          <ScrollableTableWrapper size={width}>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Мастер</TableHeader>
                  <TableHeader>Название</TableHeader>
                  <TableHeader>Оффер</TableHeader>
                  <TableHeader>База расходников</TableHeader>
                  {/* <TableHeader>Статус</TableHeader> */}
                  <TableHeader>Затраты</TableHeader>
                  <TableHeader></TableHeader>
                </tr>
              </thead>
              <tbody>
                {tasks?.map((task, index) => (
                  <Tr key={task?.id}>
                    <TableCell>{task?.master}</TableCell>
                    <TableCell>{task?.title}</TableCell>
                    <TableCell>
                      {/* {offerId.map(
                        (item) => item.id == task?.offer_id && item.offer_name
                      )} */}
                      {task?.offer_id}
                    </TableCell>
                    <TableCell>
                      {distribute?.map(
                        (item) =>
                          item.id === task?.fields[0]?.base_expense_id &&
                          item.title
                      )}
                    </TableCell>
                    {/* <TableCell>
                      <SelectStatus>
                        <StatusButton onClick={() => handleStatusClick(index)}>
                          {statuses[index]} <ChevronDown size={16} />
                        </StatusButton>
                        {openStatusIndex === index && (
                          <StatusOptions>
                            <StatusOption
                              onClick={() =>
                                handleStatusSelect(index, "Активный")
                              }
                            >
                              Активный
                            </StatusOption>
                            <StatusOption
                              onClick={() =>
                                handleStatusSelect(index, "Не активный")
                              }
                            >
                              Не активный
                            </StatusOption>
                            <StatusOption
                              onClick={() =>
                                handleStatusSelect(index, "Архивировать")
                              }
                            >
                              Архивировать
                            </StatusOption>
                          </StatusOptions>
                        )}
                      </SelectStatus>
                    </TableCell> */}
                    <TableCell>{task?.cost}$</TableCell>
                    <TableCell>
                      <IconContainer>
                        <AlertCircle
                          size={20}
                          color="#888"
                          cursor={"pointer"}
                        />
                        <Edit
                          size={20}
                          color="#379AE6"
                          cursor={"pointer"}
                          onClick={() => {
                            console.log(task);
                            
                            CreateDown("Редактировать залив", task?.flood_id);
                          }}
                        />
                      </IconContainer>
                    </TableCell>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </ScrollableTableWrapper>
        </TableContainer>
      ) : (
        <AccordionContainer>
          {tasks?.map((task, index) => (
            <AccordionItem key={task?.id}>
              <AccordionHeader onClick={() => toggleAccordion(index)}>
                <AccordionTitle>{task?.title}</AccordionTitle>
                <AccordionIcon isOpen={openAccordionIndex === index}>
                  <ChevronDown size={16} />
                </AccordionIcon>
              </AccordionHeader>
              <AccordionContent isOpen={openAccordionIndex === index}>
                <AccordionDetail>
                  <strong>Мастер:</strong> {task?.master}
                </AccordionDetail>
                <AccordionDetail>
                  <strong>Оффер:</strong>{" "}
                  {
                    offerId.find((item) => item.id === task?.offer_id)
                      ?.offer_name
                  }
                </AccordionDetail>
                <AccordionDetail>
                  <strong>База расходников:</strong>{" "}
                  {
                    distribute.find(
                      (item) => item.id === task?.fields[0]?.base_expense_id
                    )?.title
                  }
                </AccordionDetail>
                {/* <AccordionDetail>
                  <strong>Статус:</strong> {task?.status}
                </AccordionDetail> */}
                <AccordionDetail>
                  <strong>Затраты:</strong> {task?.cost}$
                </AccordionDetail>
                <IconContainer>
                  <AlertCircle size={20} color="#888" cursor={"pointer"} />
                  <Edit
                    size={20}
                    color="#379AE6"
                    cursor={"pointer"}
                    onClick={() => {
                      CreateDown("Редактировать залив", task?.flood_id);
                    }}
                  />
                </IconContainer>
              </AccordionContent>
            </AccordionItem>
          ))}
        </AccordionContainer>
      )}
    </DashboardContainer>
  );
};

// Additional Styled Component for Error Message
const ErrorMessage = styled.div`
  background-color: #ffe0e0;
  color: #ff0000;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
`;

export default Dashboard;
